import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Jumbotron, Container, Row, Col, Button } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Home from '../images/services/home.png'
import Auto from '../images/services/auto.png'
import Travel from '../images/services/travel.png'
import Business from '../images/services/business.png'
import Vina from '../images/vina.jpg'
import Kid from '../images/kid.jpg'
import Family from '../images/family.png'

import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { myContext } from '../../provider'

const Services = styled(Row)`
  background-color: #f8f8f8;
  min-height: 10rem;
  color: #4a4a4a;
  padding: 3.75rem;

  h3 {
    width: 100%;
    text-align: center;
  }
`

const Info = styled(Services)`
  margin: 5rem 0;
`

const Jumbo = styled(Jumbotron)`
  min-height: 400px;
  padding: 0;
  position: relative;
  border-radius: 3px;
  background-color: white;
`

const Post = styled.div`
  border: 1px solid #979797;
  min-height: 27.13rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  border-radius: 3px;
  font-family: 'Raleway';
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const PostContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem 0;

  span {
    font-size: 1rem;
  }

  h4 {
    font-size: 1.5rem;
    flex: 1;
    padding-top: 1rem;
  }

  a {
    color: #ee8013;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;

    &:hover {
      text-decoration: none;
    }
  }
`

const ImagePost = styled.div`
  display: flex;
  width: 100%;
  height: 13.56rem;
  overflow: hidden;
`

const H3 = styled.h3`
  font-family: 'Raleway';
  text-transform: uppercase;
  padding: 1rem;
  text-align: center;
`

const Padded = styled(Col)`
  margin: 3.13rem 0;
  p {
    margin-bottom: 2rem;
  }
`
const Icons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`
const Icon = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  img {
    width: 100%;
    background-size: contain;
    filter: invert(100%);
  }
`

const Service = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 2rem;
  h3 {
    text-align: center;
    width: 100%;
    padding-top: 1rem;
    color: #4a4a4a;
  }
`

const BackgroundImage = styled.div`
  background: url(${Kid});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 3px;
`

const JumboContent = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  flex-direction: column;

  h3 {
    font-size: 2rem;
  }

  p {
    margin: 2rem 0;
    font-size: 1rem;
  }
`

const IndexPage = ({ data }) => {
  const nodes = data.allContentfulHomePage.nodes
  const blogs = data.allContentfulBlogPost.nodes
  return (
    <Layout>
      <myContext.Consumer>
        {({ language }) => {
          const homePageText = nodes.find((node) => node.language === language)

          return (
            <>
              <SEO title="Home" />
              <Container>
                <Jumbo>
                  <Row>
                    <Col
                      xs={{
                        span: 12,
                        order: 2,
                      }}
                      md={{
                        span: 6,
                        order: 1,
                      }}
                    >
                      <JumboContent>
                        <h3>{homePageText.heroSubHeader}</h3>
                        <p>{homePageText.heroBody.heroBody}</p>
                        <div>
                          <Button>{homePageText.quoteButton}</Button>
                        </div>
                      </JumboContent>
                    </Col>
                    <Col
                      className="align-middle"
                      xs={{
                        span: 12,
                        order: 1,
                      }}
                      md={{
                        span: 6,
                        order: 2,
                      }}
                    >
                      <img src={Family} width="100%" height="auto" />
                    </Col>
                  </Row>
                </Jumbo>
              </Container>
              <Container fluid style={{ backgroundColor: '#f8f8f8' }}>
                <Container>
                  <Services className="justify-content-md-center">
                    <h3>OUR SERVICES</h3>
                    <Row>
                      <Service xs={12} md={6}>
                        <Icon>
                          <img src={Home} />
                        </Icon>
                        <h3>{homePageText.homeServiceTitle}</h3>
                        <p>{homePageText.homeService.homeService}</p>
                      </Service>

                      <Service xs={12} md={6}>
                        <Icon>
                          <img src={Auto} />
                        </Icon>
                        <h3>{homePageText.autoServiceTitle}</h3>
                        <p>{homePageText.autoService.autoService}</p>
                      </Service>
                      <Service xs={12} md={6}>
                        <Icon>
                          <img src={Travel} />
                        </Icon>
                        <h3>{homePageText.travelServiceTitle}</h3>
                        <p>{homePageText.travelService.travelService}</p>
                      </Service>
                      <Service xs={12} md={6}>
                        <Icon>
                          <img src={Business} />
                        </Icon>
                        <h3>{homePageText.businessServiceTitle}</h3>
                        <p>{homePageText.businessService.businessService}</p>
                      </Service>
                    </Row>
                  </Services>
                </Container>
              </Container>
              <Container>
                <Row className="justify-content-md-center">
                  <Padded sm={8}>
                    <p>
                      Homeowners insurance protects your home, your belongings,
                      and can protect you if you unintentionally injure someone
                      or damage their property. We have coverage for home and
                      condo owners, tenants, seasonals, rentals and other
                      properties.
                    </p>
                    <Row className="justify-content-md-center" iv>
                      <Button>{homePageText.quoteButton}</Button>
                    </Row>
                  </Padded>
                </Row>
                <hr />
                <H3>What's new in insurance?</H3>
                <Row>
                  {blogs.map((blog) => (
                    <Col xs={12} sm={4}>
                      <Post>
                        <ImagePost>
                          <img src={blog.image.sizes.src} />
                        </ImagePost>
                        <PostContent>
                          <span>Blog</span>
                          <h4>{blog[`title_${language}`]}</h4>
                          <Link to={`/blog/${blog.slug}`}>
                            <span>read more</span>
                            <span>&gt;</span>
                          </Link>
                        </PostContent>
                      </Post>
                    </Col>
                  ))}
                </Row>
                <Info>
                  <Col xs={12} md={5}>
                    <div style={{ width: '100%', height: 'auto' }}>
                      <img
                        src={Vina}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={7}>
                    <h1>ABOUT VINA INSURANCE LTD.</h1>
                    <p style={{ flex: 1 }}>
                      {homePageText.aboutVina.aboutVina}
                    </p>
                    <Button>{homePageText.readMoreButton}</Button>
                  </Col>
                </Info>
              </Container>
              <Container fluid>
                <Row>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2604.2883827252726!2d-123.08239398372159!3d49.25197968086585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486715520a4d66d%3A0x6e52e5ee687a68cc!2sVina%20Insurance%20Services%20Ltd.!5e0!3m2!1sen!2sca!4v1590984715212!5m2!1sen!2sca"
                    width="100%"
                    height="500"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  ></iframe>
                </Row>
              </Container>
            </>
          )
        }}
      </myContext.Consumer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    allContentfulHomePage {
      nodes {
        aboutVina {
          aboutVina
        }
        autoService {
          autoService
        }
        autoServiceTitle
        businessService {
          businessService
        }
        businessServiceTitle
        heroBody {
          heroBody
        }
        heroSubHeader
        homeService {
          homeService
        }
        homeServiceTitle
        language
        quoteButton
        readMoreButton
        travelServiceTitle
        travelService {
          travelService
        }
      }
    }
    allContentfulBlogPost(limit: 3) {
      nodes {
        title_english
        title_vietnamese
        slug
        image {
          sizes {
            src
          }
        }
      }
    }
  }
`

export default IndexPage
